@media print {
  html {
    visibility: hidden;
  }
  .noprintarea {
    visibility: hidden;
    display: none;
  }
  .print {
    visibility: visible !important;
    display: block !important;
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    width: auto;
  }
}

.popover {
  min-width: 400px;
  max-width: 400px; /* Max Width of the popover (depending on the container!) */
}

.text-bold {
  font-weight: bold;
}

.text-bolder {
  font-weight: bolder;
}

.category {
}

.category-title {
  font-weight: bold;
  font-size: 1.2em !important;
  border-bottom: 1px solid #efefef;
  padding-bottom: 5px;
  margin-bottom: 10px;
  margin-top: 20px;
  padding-top: 0;
}

.category-item-title {
  margin-top: 5px;
  font-size: 0.85em !important;
}

.category-item-note {
  margin-top: 10px;
}

.category-item-save {
  margin-top: 10px;
}

.nav-tabs li .warning {
  top: -5px;
  right: -5px;
  position: absolute;
  z-index: 20;
  font-size: 1.5em;
}

.nav-tabs li .no-save {
  top: -5px;
  right: 15px;
  position: absolute;
  z-index: 20;
  font-size: 1.5em;
}

.bounce {
  display: inline-block;
  position: relative;
  -moz-animation: bounce 0.5s infinite linear;
  -o-animation: bounce 0.5s infinite linear;
  -webkit-animation: bounce 0.5s infinite linear;
  animation: bounce 0.5s infinite linear;
  colr: 000;
}

@-webkit-keyframes bounce {
  0% {
    top: -5px;
  }
  50% {
    top: -0.2em;
  }
  70% {
    top: -0.3em;
  }
  100% {
    top: -5px;
  }
}

@-moz-keyframes bounce {
  0% {
    top: -5px;
  }
  50% {
    top: -0.2em;
  }
  70% {
    top: -0.3em;
  }
  100% {
    top: -5px;
  }
}

@-o-keyframes bounce {
  0% {
    top: -5px;
  }
  50% {
    top: -0.2em;
  }
  70% {
    top: -0.3em;
  }
  100% {
    top: -5px;
  }
}

@-ms-keyframes bounce {
  0% {
    top: -5px;
  }
  50% {
    top: -0.2em;
  }
  70% {
    top: -0.3em;
  }
  100% {
    top: -5px;
  }
}

@keyframes bounce {
  0% {
    top: -5px;
  }
  50% {
    top: -0.2em;
  }
  70% {
    top: -0.3em;
  }
  100% {
    top: -5px;
  }
}

/*anchor*/
a {
  color: #32323a;
}

a:hover {
  color: #222227;
}

/*panel*/
.panel {
  border: none;
  box-shadow: none;
}

.panel-heading {
  border-color: #eff2f7;
  font-size: 13px;
  font-weight: 400;
  background: #fafafa;
  text-transform: uppercase;
  padding: 15px;
}

.panel-title {
  color: #32323a;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 0;
  font-family: 'Open Sans', sans-serif;
}

.panel-primary .tools a {
  color: #fff;
}

/*label*/

.label {
  padding: 0.5em 0.8em;
}

.label-default {
  background-color: #a1a1a1;
}

.label-primary {
  background-color: #59ace2;
}

.label-success {
  background-color: #a9d86e;
}

.label-info {
  background-color: #8175c7;
}

.label-warning {
  background-color: #fcb322;
}

.label-danger {
  background-color: #ff6c60;
}

.label-inverse {
  background-color: #344860;
}

/*text color*/

.text-danger {
  color: #ff6c60;
}

.text-muted {
  color: #a1a1a1;
}

.text-primary {
  color: #59ace2;
}

.text-warning {
  color: #fcb322;
}

.text-success {
  color: #22af1f;
}

.text-info {
  color: #8175c7;
}

/*modal*/

.modal-content {
  box-shadow: none;
  border: none;
}

.modal-header {
  background: #fafafa;
  color: #333;
  border-radius: 5px 5px 0 0;
  -webkit-border-radius: 5px 5px 0 0;
  /*border-bottom: none;*/
}

.modal-header .close {
  margin-top: 0;
}

/*text input*/

.form-control {
  border: 1px solid #e2e2e4;
  box-shadow: none;
  color: #666666;
}

.form-control:focus,
#focusedInput {
  border: 1px solid #00a8b3;
  box-shadow: none;
}

.form-horizontal .control-label {
  font-weight: 300;
  font-size: 14px;
}

input,
textarea,
select,
button {
  outline: none !important;
}

/*list*/

ul {
  padding-left: 0;
}

/*button*/

.btn-default {
  background-color: #c7cbd6;
  border-color: #c7cbd6;
  color: #fff;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  background-color: #b0b5b9;
  border-color: #b0b5b9;
  color: #fff;
}

.btn-primary {
  background-color: #1fb5ad;
  border-color: #1fb5ad;
  color: #ffffff;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-color: #1ca59e;
  border-color: #1ca59e;
  color: #ffffff;
}

.btn-success {
  background-color: #95b75d;
  border-color: #95b75d;
  color: #ffffff;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  background-color: #88a755;
  border-color: #88a755;
  color: #ffffff;
}

.btn-info {
  background-color: #57c8f1;
  border-color: #57c8f1;
  color: #ffffff;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  background-color: #53bee6;
  border-color: #53bee6;
  color: #ffffff;
}

.btn-warning {
  background-color: #f3c022;
  border-color: #f3c022;
  color: #ffffff;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  background-color: #e4ba00;
  border-color: #e4ba00;
  color: #ffffff;
}

.btn-danger {
  background-color: #fa8564;
  border-color: #fa8564;
  color: #ffffff;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  background-color: #ec6459;
  border-color: #ec6459;
  color: #ffffff;
}

.btn-white {
  box-shadow: none !important;
}

/*Rounded Button*/

.btn-round {
  border-radius: 30px;
  -webkit-border-radius: 30px;
}

/*dropdown shadow*/

.btn-group.open .dropdown-toggle,
.btn-white.active,
.btn:active,
.btn.active {
  box-shadow: none;
}

/*dropdown select bg*/
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background-color: #474752;
  color: #ffffff;
  text-decoration: none;
}

.dropdown-menu > li > a:hover small,
.dropdown-menu > li > a:focus small,
.dropdown-menu > li.active small {
  color: #ffffff !important;
}

/*split dropdown btn*/

.btn-white {
  background-clip: padding-box;
  background-color: #ffffff;
  border-color: rgba(150, 160, 180, 0.3);
  box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.05) inset;
}

/*breadcrumbs*/

.breadcrumb {
  background-color: #fff;
}

/*tab*/

.nav-tabs > li > a {
  margin-right: 1px;
}

/*collapse*/

.panel-default > .panel-heading {
  background-color: #ffffff;
  border-color: #dddddd;
  color: #797979;
}

/*nav inverse*/

.navbar-inverse {
  background-color: #32323a;
  border-color: #32323a;
}

.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:hover,
.navbar-inverse .navbar-nav > .active > a:focus,
.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .open > a:focus {
  background-color: #474752;
}

.navbar-inverse .navbar-nav > li a:hover {
  color: #fff;
}

.navbar-inverse .navbar-nav > li > ul > li a:hover {
  color: #fff;
}

.navbar-inverse .navbar-brand {
  color: #ffffff;
}

.navbar-inverse .navbar-nav > li > a {
  color: #fff;
}

.navbar-inverse .navbar-nav > .dropdown > a .caret {
  border-bottom-color: #fff;
  border-top-color: #fff;
}

.navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
  color: #000;
}

.navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover {
  color: #fff;
}

/*nav justified*/

.nav-justified {
  width: auto !important;
}

.nav-justified li:last-child > a:hover,
.nav-justified li.active:last-child > a {
  border-radius: 0 4px 0 0 !important;
  -webkit-border-radius: 0 4px 0 0 !important;
}

/*list group*/

.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  background-color: #00a8b3;
  border-color: #00a8b3;
  color: #ffffff;
  z-index: 2;
}

.list-group-item-heading {
  font-weight: 300;
}

/*progress*/

.progress {
  box-shadow: none;
  background: #f0f2f7;
}

/*alert*/

.alert-success,
.alert-danger,
.alert-info,
.alert-warning {
  border: none;
}

/*table*/

.table thead > tr > th,
.table tbody > tr > th,
.table tfoot > tr > th,
.table thead > tr > td,
.table tbody > tr > td,
.table tfoot > tr > td {
  padding: 10px;
}

/*pagination*/

.pagination > li > a,
.pagination > li > span {
  background-color: #eff2f7;
  border: 1px solid #eff2f7;
  float: left;
  line-height: 1.42857;
  margin-left: 1px;
  padding: 6px 12px;
  position: relative;
  text-decoration: none;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus,
.pagination > li.active > a,
.pagination > li.active > a:hover {
  background-color: #2eb4ad;
  border-color: #2eb4ad;
  color: #fff;
}

/*--*/

.has-success .form-control:focus,
.has-error .form-control:focus,
.has-warning .form-control:focus {
  box-shadow: none;
}

.panel-group .panel + .panel {
  margin-top: 1px;
}

.progress {
  border-radius: 50px;
  -webkit-border-radius: 50px;
}

.progress-bar {
  box-shadow: none;
}

/*well*/
.well {
  box-shadow: none;
}

/*--------*/

/*.form-control:-moz-placeholder {*/
/*color: #000;*/
/*}*/
/*.form-control::-moz-placeholder {*/
/*color: #000;*/
/*opacity: 1;*/
/*}*/
/*.form-control:-ms-input-placeholder {*/
/*color: #000;*/
/*}*/
/*.form-control::-webkit-input-placeholder {*/
/*color: #000;*/
/*}*/

/*.form-control {*/
/*color:#000!important;*/
/*}*/
